import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to update clothing size information`}</h1>
    <p>{`Once logged in, navigate to `}<strong parentName="p">{`MySize`}</strong>{`.`}</p>
    <p>{`Then click `}<strong parentName="p">{`Update Size`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      